<template>
  <div class="">
    <el-form :model="formDocument" :rules="rules" ref="formDocument">
      <TopBarTitleComponent
        title="Editar Liquidación de Compra"
        :showBackIcon="true"
      >
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4 pt-4">
        <b-row>
          <b-col>
            <div class="card box-shadow" v-if="apiResponse.success">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="user_business_client_id">
                          <SelectBusinessComponent
                            :title="'Proveedor'"
                            :onlyProviders="true"
                            @onChangeSelect="onChangeSelectBusiness"
                            :setUserBusinessClientId="
                              formDocument.user_business_client_id
                            "
                          />
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="email">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Correo</label>
                          </slot>
                          <el-input
                            type="text"
                            placeholder=""
                            class=""
                            v-model="formDocument.email"
                          ></el-input>
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="user_business_e_point_id">
                          <SelectEmissionPointsComponent
                            @onChangeSelect="onChangeSelectEmissionPoints"
                            :setEmissionPointId="
                              formDocument.user_business_e_point_id
                            "
                          />
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                        <InputDatePickerComponent
                          @onChangeDatePicker="onChangeDatePicker"
                          prop="emission_date"
                          placeholder=""
                          title="Fecha de emisión"
                          :isDisabledDate="true"
                          :setDate="formDocument.emission_date"
                        />
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                        <el-form-item prop="sri_forms_payment_id">
                          <slot name="label">
                            <label class="font-semi-bold m-0"
                              >Formas de pago</label
                            >
                          </slot>
                          <SelectSriFormsPaymentsComponent
                            @onChangeSelect="onChangeSriFormsPaymentsSelect"
                            :setSriFormsPaymentId="
                              formDocument.sri_forms_payment_id
                            "
                          />
                        </el-form-item>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <TableInputProducts
                  @onToogleProducts="onToogleProducts"
                  :setProducts="formDocument.products"
                />
                <section class="aditional-documents mt-4">
                  <b-row>
                    <b-col xs="12" sm="12" md="4">
                      <DocumentAdditionalInfoComponent
                        @onAdditionals="onAdditionals"
                      />
                    </b-col>
                    <b-col xs="12" sm="12" md="4" offset-md="4">
                      <DocumentCalculator
                        :setProducts="formDocument.products"
                        @onChangeSwitchDiscount="onChangeSwitchDiscount"
                      />
                    </b-col>
                  </b-row>
                </section>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import SelectBusinessComponent from "../../../../components/SelectBusiness";
import SelectEmissionPointsComponent from "../../../../components/SelectEmissionPoints";
import TableInputProducts from "../../../components/TableInputProducts";
import SelectSriFormsPaymentsComponent from "../../../../components/SelectSriFormsPayments";
import DocumentAdditionalInfoComponent from "../../../components/DocumentAdditionalInfo";
import DocumentCalculator from "../../../components/DocumentCalculator";

import moment from "moment";
import liquidationService from "../services/liquidationService";
import Validate from "@/assets/validate";
const DATE_AT = moment().format("YYYY-MM-DD");

export default {
  name: "LiquidationEditPage",
  data: () => ({
    apiResponse: {
      success: false,
      message: null
    },
    products: [],
    product_select: "",
    liquidation: null,
    formDocument: {
      user_business_id: null,
      user_business_client_id: "",
      user_business_e_point_id: "",
      sri_forms_payment_id: 7,
      email: "",
      emission_date: DATE_AT,
      discount: 0,
      discount_type: "",
      products: [],
      additional_extras: []
    },
    rules: {
      user_business_client_id: [Validate.rules.required("change")],
      user_business_e_point_id: [Validate.rules.required("change")],
      sri_forms_payment_id: [Validate.rules.required("change")],
      emission_date: [Validate.rules.required()],
      email: [Validate.rules.required()]
    }
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    async getLiquidation(liquidationId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await liquidationService
          .getLiquidationById(liquidationId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response) {
          this.liquidation = response.data.data;
          this.handleDataForm();
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },
    handleDataForm() {
      const {
        user_business_client_id,
        user_business_id,
        products,
        user_business_e_point_id,
        sri_forms_payment_id,
        emission_date,
        email,
        discount,
        discount_type,
        additional_extras
      } = this.liquidation;

      this.formDocument = {
        ...this.formDocument,
        ...{
          user_business_client_id,
          user_business_id,
          products,
          user_business_e_point_id,
          sri_forms_payment_id,
          emission_date,
          email,
          discount,
          discount_type,
          additional_extras
        }
      };
    },
    handleSaveClick() {
      this.$refs.formDocument.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await liquidationService
              .updateLiquidationId(this.liquidation.id, this.formDocument)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            if (response.data.success) {
              const { id: liquidationId } = response.data.data;
              this.goRouter("sales.liquidations.view", {
                id: liquidationId
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    onChangeDatePicker({ date }) {
      this.formDocument.emission_date = date;
    },
    onChangeSelectBusiness({ client }) {
      const { email, id } = client;
      this.formDocument.email = email;
      this.formDocument.user_business_client_id = id;
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.formDocument.user_business_e_point_id = emissionPointId;
    },
    onToogleProducts({ products }) {
      this.formDocument.products = [...products];
    },
    onChangeSwitchDiscount({ discount, type }) {
      this.formDocument.discount = discount;
      this.formDocument.discount_type = type;
    },
    onChangeSriFormsPaymentsSelect({ sriFormsPaymentsSelect }) {
      this.formDocument.sri_forms_payment_id = sriFormsPaymentsSelect;
    },
    onAdditionals({ additionals }) {
      this.formDocument.additional_extras = additionals;
    }
  },
  components: {
    ButtonPrimaryComponent,
    TopBarTitleComponent,
    InputDatePickerComponent,
    SelectBusinessComponent,
    SelectEmissionPointsComponent,
    TableInputProducts,
    SelectSriFormsPaymentsComponent,
    DocumentAdditionalInfoComponent,
    DocumentCalculator
  },
  mounted() {
    const { id: liquidationId } = this.$route.params;
    if (liquidationId) {
      this.getLiquidation(liquidationId);
    }
  }
};
</script>

<style lang="sass" scoped></style>
